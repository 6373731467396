import React from 'react';
import './Home_Services.css';
import Codefile from '../Resourses/Home/Code File.png'
import dashboard from '../Resourses/Home/dashboard 1.png'
import Ecommerce from '../Resourses/Home/E-commerce.png'
import Internet from '../Resourses/Home/Internet.png'
import Mobile from '../Resourses/Home/Mobile.png'
import TechnicalSupport from '../Resourses/Home/Technical Support.png'
export const Hservices = ()=>{
    return(
        <div className = "component">
            <div className = "heading">
            <h1>Software Development 
                <br></br>Services: From Concept To Completion
                </h1>
            </div>
            <div className = "boxes">
                     <div className='box'>
                        <img src={Codefile} alt = "popo"></img>
                        <h1>Custom Software <br></br>Development</h1>
                        <p>Unlock the full potential of your business with custom software solutions designed to streamline operations and enhance productivity</p>
                     </div>

                     <div className = 'box'>
                        <img  src={Internet} alt="po"></img>
                        <h1>Web App <br></br>Development</h1>
                        <p>From simple landing pages to complex e-commerce platforms, we ensure a seamless user experience and robust functionality across all devices.</p>
                     </div>

                     <div className = 'box'>
                        <img src={Ecommerce} alt = "popo"></img>
                        <h1>E-Commerce App <br></br>Development</h1>
                        <p>From intuitive product catalogs to secure payment gateways, we create seamless shopping experiences that drive conversions and foster customer loyalty.</p>
                        </div>

                     <div className = 'box'>
                        <img src={dashboard} alt = "popo"></img>
                        <h1>UX/UI <br></br>Design</h1>
                        <p>Create beautiful, user-friendly designs for websites, mobile apps, and other human-machine interfaces which enhances user satisfaction.</p>
                        </div>

                     <div className = 'box'>
                        <img src = {Mobile} alt="Mobile.png"></img>
                        <h1>Mobile App <br></br>Development</h1>
                        <p>Transform your ideas into innovative mobile applications with our expertise in app development. </p>
                        </div>

                     <div className = 'box'>
                        <img src = {TechnicalSupport} alt = "popo"></img>
                        <h1>Consulting and Advisory Services</h1>
                        <p>Gain strategic insights and guidance from our team of experts to navigate the complexities of the digital landscape</p>
                     </div>

            </div>
          </div>
      )
 }