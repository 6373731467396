import React from 'react';
import TitleContainer from '../../components/TitleContainer';
import { Service, Service2 } from '../../components/ServiceToolBox';
import I50 from '../../Resourses/Services/I50.jpg';
import I51 from '../../Resourses/Services/I51.jpg';
import I52 from '../../Resourses/Services/I52.jpg';
import I53 from '../../Resourses/Services/I53.jpg';
import I54 from '../../Resourses/Services/I54.jpg';
import I55 from '../../Resourses/Services/I55.jpg';

const Consulting = () => {
    return (
        <>
        <TitleContainer attractive_banner = "From Concept To Completion" main_big_text = "Consulting and Advisory Services" small_description = "Strategic Guidance for Digital Success" src_container1={I50} />
        <Service box_title="Comprehensive Analysis" 
       box_para1="We conduct in-depth assessments of your web development or software requirements, analyzing your business goals, technical
        needs, and user expectations to develop a clear understanding of your project's scope and objectives."
        box_src={I51} box_alt="Logo" /> 
     
     <Service2 box_title="Tailored Solutions"
       box_para1="Based on our analysis, we provide customized recommendations and solutions that are tailored to your specific requirements, ensuring that 
       you receive strategic guidance that aligns perfectly with your business goals and objectives"
      box_src={I52} box_alt="Logo"/>


      <Service box_title="Technology Selection" 
      box_para1="We help you navigate the myriad of technologies and platforms available, guiding you towards the best-fit solutions that meet your technical 
      requirements, budget constraints, and long-term scalability needs."
       box_src={I53} box_alt="Logo" /> 
    
    <Service2 box_title="Project Planning and Management"
      box_para1="Our experienced project managers work closely with you to develop detailed project plans, establish clear milestones and timelines, and allocate resources effectively 
      to ensure that your project stays on track and delivers on time and within budget."
     box_src={I54} box_alt="Logo"/>

     <Service box_title="Quality Assurance and Testing" 
      box_para1="We employ rigorous quality assurance and testing processes to ensure that your web development or software project meets the highest standards of quality, performance, and reliability, 
      minimizing the risk of issues and errors and ensuring a smooth and successful launch"
       box_src={I55} box_alt="Logo" />
        </>
    );
};

export default Consulting;