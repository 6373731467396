import React from 'react';
import {Hservices} from '../components/Home/Home_Services'

const AboutPage = () => {
    return (
        <div>
            <h1 style={{ padding:'70px' }}>This is a Blank Page</h1>
            {/* Add your content here */}
            <Hservices />
        </div>
    );
};

export default AboutPage;