import './App.css';
import React, { useEffect } from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Navbar from './components/header';
import { Footer } from './components/footer';
import Home from './pages/Home';
import About from './pages/About';
import Custom from './pages/ServicePages/Custom';
import WebApp from './pages/ServicePages/WebApp';
import UiUx from './pages/ServicePages/UiUx';
import MobileApp from './pages/ServicePages/MobileApp';
import Ecomm from './pages/ServicePages/Ecomm';
import Consulting from './pages/ServicePages/Consulting';
import OurTeam from './pages/Ourteam';

function App() {
  useEffect(() => {
    document.title = 'Novaluna Technologies';
      }, []);

  return (
    <div className="App">
      <Navbar />
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path='/Home' element={<Home />} />
          <Route path='/About' element={<About />} />
          <Route path='/WebApp' element={<WebApp />} />
          <Route path='/Custom' element={<Custom />} />
          <Route path='/UiUx' element={<UiUx />} />
          <Route path='/MobileApp' element={<MobileApp />} />
          <Route path='/Ecomm' element={<Ecomm />} />
          <Route path='/Consulting' element={<Consulting />} />
          <Route path='/OurTeam' element={<OurTeam />} />
        </Routes>
      </BrowserRouter>
      <Footer />

    </div>
  );
}

export default App;
