import React from 'react';
import TitleContainer from '../../components/TitleContainer';
import I60 from '../../Resourses/Services/I60.jpg';
import I61 from '../../Resourses/Services/I61.png';
import I62 from '../../Resourses/Services/I62.jpg';
import I63 from '../../Resourses/Services/I63.jpg';
import I64 from '../../Resourses/Services/I64.png';
import I65 from '../../Resourses/Services/I65.jpg';
import { Service, Service2 } from '../../components/ServiceToolBox';

const UiUx = () => {
    return (
        <>
        <TitleContainer attractive_banner = "From Concept To Completion" main_big_text = "UI/UX Design" small_description = "Crafting Engaging User Experiences" src_container1={I60} />
        <Service box_title="User Research" 
       box_para1="We begin by conducting thorough user research to gain insights into your target audience's needs,
        preferences, and behaviors, informing our design decisions
        and ensuring that we create experiences that resonate with your users."
        box_src={I61} box_alt="Logo" /> 
     
     <Service2 box_title="Wireframing and Prototyping"
       box_para1="We create wireframes and interactive prototypes to visualize the structure and flow of your website or app, allowing you to preview and test 
       different design concepts and iterate quickly based on user feedback.We design a custom software solution that precisely fits your specifications and aligns with your business objectives.."
      box_src={I62} box_alt="Logo"/>


      <Service box_title="Visual Design" 
      box_para1="Our talented designers create visually stunning interfaces that captivate your audience and communicate your brand identity effectively, incorporating engaging visuals, compelling typography,
       and intuitive layouts to create memorable and immersive experiences."
       box_src={I63} box_alt="Logo" /> 
    
    <Service2 box_title="Usability Testing"
      box_para1="We conduct usability testing to evaluate the effectiveness and efficiency of our designs, identifying areas for improvement and refining our solutions to
       ensure that they meet the needs and expectations of your users.."
     box_src={I64} box_alt="Logo"/>

     <Service box_title="Accessibility" 
      box_para1="We design with accessibility in mind, ensuring that our interfaces are usable and enjoyable for all users, including those with disabilities or impairments, 
      in compliance with industry standards and best practices."
       box_src={I65} box_alt="Logo" />
        </>
    );
};

export default UiUx;