import React from 'react';
import TitleContainer from '../../components/TitleContainer';
import { Service, Service2 } from '../../components/ServiceToolBox';
import I40 from '../../Resourses/Services/I40.jpg';
import I41 from '../../Resourses/Services/I41.jpg';
import I42 from '../../Resourses/Services/I42.jpg';
import I43 from '../../Resourses/Services/I43.png';
import I44 from '../../Resourses/Services/I44.jpg';
import I45 from '../../Resourses/Services/I45.png';
const Ecomm = () => {
    return (
        <>
        <TitleContainer attractive_banner = "From Concept To Completion" main_big_text = "E-Commerce App Development" small_description = "Turning Your Vision into an Online Marketplace" src_container1={I40} />
        <Service box_title="Product Catalog" 
       box_para1="We create visually stunning and easy-to-navigate product catalogs that showcase your offerings in the best possible light. With advanced filtering, sorting, and search options,
        we make it effortless for users to find and explore your products, leading to higher engagement and conversion rates."
        box_src={I41} box_alt="Logo" /> 
     
     <Service2 box_title="Secure Transactions"
       box_para1="Security is paramount in e-commerce, which is why we prioritize the implementation of secure payment gateways and encryption 
       protocols to safeguard sensitive financial information and protect your customers from fraud and identity theft."
      box_src={I42} box_alt="Logo"/>


      <Service box_title="Inventory Management" 
      box_para1="Efficient inventory management is essential for e-commerce success. Our custom-built inventory management systems allow you to track stock levels, manage product variants, and
       streamline order fulfillment processes, ensuring that you always have the right products available to meet customer demand."
       box_src={I43} box_alt="Logo" /> 
    
    <Service2 box_title="Customer Engagement"
      box_para1="We help you build lasting relationships with your customers through personalized experiences and targeted engagement strategies. From personalized recommendations and loyalty programs to interactive 
      features and social sharing tools, we create opportunities for meaningful interactions that drive repeat business and customer loyalty."
     box_src={I44} box_alt="Logo"/>

     <Service box_title="Analytics" 
      box_para1="Data-driven insights are key to optimizing your e-commerce performance. We integrate advanced analytics tools that provide valuable insights into customer behavior, sales performance, and marketing effectiveness, 
      empowering you to make informed decisions and drive continuous improvement in your e-commerce operations."
       box_src={I45} box_alt="Logo" />
        </>
    );
};

export default Ecomm;