import React from 'react';
import {Hservices} from '../components/Home/Home_Services'
import {Otech} from '../components/Home/OurTech'
import TitleContainer from '../components/TitleContainer';
import image_1 from '../components/Resourses/Home/image_1.png'
import About from '../components/Home/About';
import { Project } from '../components/Home/Projects';


const Home = () => {
    return (
        <div>
            <TitleContainer attractive_banner = "REACH NEW AUDIENCES" main_big_text = "Digital growth tailored to your business" small_description = "We help you reach new markets and stand out from the competition" src_container1 = {image_1} />
            <About />
            <Hservices />
            <Project />
            <Otech />
        </div>
    );
};

export default Home;