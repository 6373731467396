import React, { useState, useEffect, useRef } from 'react';
import './header.css';
import NL from '../Resourses/Header&Footer/NL.svg';

const Navbar = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const contactRef = useRef(null);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const closeDropdown = () => {
        setShowDropdown(false);
    };

    const handleContactClick = () => {
        setShowContact(!showContact);
    };

    const handleClick = (event) => {
        if (contactRef.current && !contactRef.current.contains(event.target)) {
            setShowContact(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    return (
        <header>
            <div className="logo">
                <img src={NL} alt="logo" />
            </div>
            <nav>
                <ul className={showMenu ? 'menu show' : 'menu'}>
                    <li><a href="/Home">Home</a></li>
                    <li><a href="Home#About">About</a></li>
                    <li className={`dropdown ${showDropdown ? 'show' : ''}`} onMouseLeave={closeDropdown}>
                        <a href="/WebApp" onClick={toggleDropdown}>Services</a>
                        <ul className="dropdown-content">
                            <li><a href="/WebApp">Website Development</a></li>
                            <li><a href="/MobileApp">Mobile App development</a></li>
                            <li><a href="/Custom">Custom Software Development</a></li>
                            <li><a href="/Ecomm">E-Commerce App Development</a></li>
                            <li><a href="/Consulting">Consulting and Advisory Services</a></li>
                            <li><a href="/UiUx">UI/UX Design</a></li>
                        </ul>
                    </li>
                    <li><a href="/Home#Project">Projects</a></li>
                    <li><a href="/OurTeam">Our Team</a></li>
                    <li className='bdr'>
                        <div className='hh1'>Contact</div>
                            <div className='contact_i'>
                            <div className='hh2'>Phone number</div>
                            <div className='pp'><a href='https://wa.me/91%E2%80%AA8123501783'>81235 01783</a></div>
                        </div>
                        <div className='contact_i'>
                            <div className='hh2'>E-mail</div>
                            <div className='pp'>info@novalunatech.com</div>
                        </div>
                    </li>
                    <li className='bd'><div style={{color: '#fff', padding:'5px' }} className='bde'  onClick={handleContactClick}>Contact</div></li>
                </ul>
                <div className="menu-toggle" onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className='contact' style={{ display: showContact ? 'flex' : 'none' }} ref={contactRef}>
                    <div className='contact_i'>
                        <div className='hh2'>Contact number</div>
                        <div className='pp'><a href='https://wa.me/91%E2%80%AA8123501783'>81235 01783</a></div>
                    </div>
                    <div className='contact_i'>
                        <div className='hh2'>E-mail</div>
                        <div className='pp'><a href='mailto: info@novalunatech.com'>info@novalunatech.com</a> </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Navbar;
