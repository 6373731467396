import React from 'react';
import './OurTech.css';
import Boxa from '../Resourses/Home/spring.png';
import Boxb from '../Resourses/Home/java.png';
import Boxc from '../Resourses/Home/aws.png';
import Boxd from '../Resourses/Home/mysql.png';
import Boxe from '../Resourses/Home/apple.png';
import Boxf from '../Resourses/Home/node.png';
import Boxg from '../Resourses/Home/pgsql.png';
import Boxh from '../Resourses/Home/react.png';
import Boxi from '../Resourses/Home/android.png';
import Boxj from '../Resourses/Home/mongodb.png';
export const Otech =()=>{
        return(
            <div className='all-technologies-container'>
               <div className='ourtech'>
                Our<br/><div className='tech'>Technologies</div>
                </div>  
            <div className="container">
                <div className='otbox'><img className='techo' src= {Boxa} alt="spring"/></div>
                <div className='otbox'><img className='techo' src= {Boxb} alt="java"/></div>
                <div className='otbox'><img className='techo' src= {Boxc} alt="aws"/></div>
                <div className='otbox'><img className='techo' src= {Boxd} alt="mysql"/></div>
                <div className='otbox'><img className='techo' src= {Boxe} alt="apple"/></div>
                <div className='otbox'><img className='techo' src= {Boxf} alt="node"/></div>
                <div className='otbox'><img className='techo' src= {Boxg} alt="pgsql"/></div>
                <div className='otbox'><img className='techo' src= {Boxh} alt="react"/></div>
                <div className='otbox'><img className='techo' src= {Boxi} alt="android"/></div>
                <div className='otbox'><img className='techo' src= {Boxj} alt="mongodb"/></div>
                
            </div>        
             
            </div>

        );
    }