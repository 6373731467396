import React from 'react';
import "./ourteam.css";
import Allourteam from "../components/Allourteam";
import Santaz from "../components/Resourses/OT1.jpg";

function OurTeam() {
    return(
        <div className="Teammates-container">
            <div className="Teammates-description">
                <h1 className="Teammates-green-text">It's different working with us</h1>
                <p className="Teammates-para">We curate senior leadership and creative for each client, so there's no one-size-fits-all solution. This approach ensures you get the deep knowledge that comes with experience in your industry, and a flat</p>
                <div className="Teammates-button-div">
                    <button className="Teammates-button" type = "button" >Read more</button>
                </div>
            </div>
            <div className="Teammates-people-list">
                <Allourteam person_img = {Santaz} Person_name = "Shahana Nanakkal" Person_designation = "Technical Manager " my_pic = "where?"/>
                <hr className="Teammates-hrline"/>
                <Allourteam person_img = {Santaz} Person_name = "Ajith M Nair" Person_designation = "FullStack Java Developer" my_pic = "where?"/>
                
            </div>
        </div>


    )
}

export default OurTeam;