import React from 'react';
import TitleContainer from '../../components/TitleContainer';
import I10 from '../../Resourses/Services/I10.jpg';
import I11 from '../../Resourses/Services/I11.jpg';
import I12 from '../../Resourses/Services/I12.jpg';
import I13 from '../../Resourses/Services/I13.png';
import I14 from '../../Resourses/Services/I14.jpg';
import I15 from '../../Resourses/Services/I15.jpg';
import { Service, Service2 } from '../../components/ServiceToolBox';

const WebApp = () => {
    return (
        <>
        <TitleContainer attractive_banner = "From Concept To Completion" main_big_text = "Website Development" small_description = "Crafting Dynamic Online Experiences" src_container1={I10} />
        <Service box_title="Responsive Design"
        box_para1="Our websites are meticulously crafted to adapt seamlessly to various screen
         sizes and devices, ensuring an optimal browsing experience for all users. Whether your audience accesses your site from a desktop, tablet, or smartphone, they'll
         enjoy consistent functionality and visual appeal."
        box_src={I11} box_alt="Logo" /> 
      
        <Service2  box_title="Customization"
        box_para1="We understand that every business is unique, which is why we take a personalized 
        approach to website development. From design elements to functionality, we tailor each website
         to align perfectly with your brand identity and meet your specific goals and objectives."
        box_src={I12} box_alt="Logo"/>

      <Service  box_title="User-Friendly Interface"
        box_para1="User experience is at the forefront of our website development process. We prioritize intuitive navigation, clear layouts, and engaging visuals to ensure that visitors can effortlessly find what they're looking for
         and enjoy a seamless browsing experience from start to finish."
       box_src={I13} box_alt="Logo" /> 

       <Service2 box_title="Scalability"
       box_para1="Our websites are built with scalability in mind, allowing for easy expansion
        and updates as your business evolves. Whether you're adding new products, services, or features, our flexible architecture ensures that your website can
        grow with you without sacrificing performance or user experience."
       box_src={I14} box_alt="Logo"/>

       <Service  box_title="SEO Optimization"
        box_para1="Visibility is key to online success, which is why we implement robust SEO strategies to help your website rank higher in search engine results. From keyword optimization to metadata enhancements, we employ proven tactics to increase your online
         visibility and drive organic traffic to your site."
       box_src={I15} box_alt="Logo" />
        </>
    );
};

export default WebApp;