import React from "react";
import './Projects.css';
import Slima from '../Resourses/Home/Slima.png';
import AlMadina from '../Resourses/Home/AlMadina.jpg';
import Matjar from '../Resourses/Home/Matjar.webp';
import Adcs from '../Resourses/Home/Adcs.png';
import TechnoBear from '../Resourses/Home/TechnoBear.jpg';


export const Project =()=>{
 

    return(
        <div className="p" id="Project">
            <div className="project_title">
                <h1 >Our<br></br><span className="green">Projects</span></h1>
            </div>
            <div className="project_main_box">
                <div className = 'project_box'>
                    <h1>Ecommerce</h1>
                    <hr className="project_line"></hr>
                    <div className="project_image">
                        <a href="http://www.almadinahypermarket.ae/"><img  src={AlMadina} alt="Al Madina"></img></a>
                        <a href="https://www.matjar.online/"><img  src={Matjar} alt="Matjar"></img></a>
                        <a href="https://technobear.co/"><img  src={TechnoBear} alt="Matjar"></img></a>
                        
                    </div>
                </div>
                <div className = 'project_box'>
                    <h1>Transportation</h1>
                    <hr className="project_line"></hr>
                    <div className="project_image">
                        <a href="https://www.adcs.ae/"><img  src={Adcs} alt="Adcs"></img></a>
                    </div>
                </div>
                <div className = 'project_box'>
                    <h1>Real Estate</h1>
                    <hr className="project_line"></hr>
                    <div className="project_image">
                        <a href="http://silma.matjar.solutions/"><img src={Slima} alt="Slima"></img></a>
                    </div>
                </div>
            </div>
        
        </div>
    )
}