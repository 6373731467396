import React from 'react';
import './About.css';
import AboutImg1 from '../Resourses/Home/About1..png'
import AboutImg2 from '../Resourses/Home/About2.png'

const About = () => {
    return (
        <>
           <div id="About" className='AboutContainer'>
            <div className='AboutTitle'>About Us</div>
            <div className='AboutBox1'>
                <div className='AboutText'>
                    <h1>
                    Helping businesses  succeed through the <br/> power of software.
                    </h1>
                    <p>
                    At Novaluna Technologies, we excel in full-stack Java/Spring Boot, MySQL, MongoDB, ReactJS, and mobile app development for Android and iOS. Our focus is on delivering customized solutions to drive tangible value, fostering long-term partnerships with our clients. Reach out to us with your development needs!
                    </p>
                </div>
                <div className='AboutImg1'>
                    <img  src={AboutImg1} alt='About'/>
                </div>
            </div>
            <div className='AboutBox2'>
                <div className='AboutImg2'>
                <img  src={AboutImg2} alt='About'/>
                </div>
                <div className='AboutText'>
                    <h2>
                        Our Vision
                    </h2>
                    <p>
                        Our vision is to emerge as the premier global provider of next-generation technology solutions and services, specializing in custom web and mobile application development.
                    </p>
                    <br/>
                    <h2>
                        Our Mission
                    </h2>
                    <p>
                    Our vision is to emerge as the premier global provider of next-generation technology solutions and services, specializing in custom web and mobile application development.
                    </p>
                </div>
            </div>
            </div> 
        </>
    );
};

export default About;