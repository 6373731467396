import React from 'react';
import TitleContainer from '../../components/TitleContainer';
import I30 from '../../Resourses/Services/I30.jpg';
import I31 from '../../Resourses/Services/I31.jpg';
import I32 from '../../Resourses/Services/I32.jpg';
import I33 from '../../Resourses/Services/I33.jpg';
import I34 from '../../Resourses/Services/I34.jpg';
import I35 from '../../Resourses/Services/I35.jpg';
import { Service, Service2 } from '../../components/ServiceToolBox';

const Custom = () => {
    return (
        <>
        <TitleContainer attractive_banner = "From Concept To Completion" main_big_text = "Custom Software Development" small_description = "Empowering Your Business with Tailored Solutions" src_container1={I30} />
        <Service box_title="Understanding Your Requirements" box_subtitle1="Comprehensive Analysis:"
        box_para1="We begin by conducting a thorough analysis of 
        your business needs,workflows, and technical requirements."
        box_subtitle2="Stakeholder Collaboration:" box_para2="Our team collaborates closely 
        with your stakeholders to gather detailed insights into your goals, budget, and timeline." box_src={I31} box_alt="Logo" /> 
      
      <Service2 box_title="Designing Your Solution" box_subtitle1="Tailored Solutions:"
        box_para1="We design a custom software solution that precisely fits your specifications and aligns with your business objectives.."
        box_subtitle2="Utilizing Latest Technologies:" box_para2="Leveraging the latest technologies and development methodologies, we create scalable, secure, 
        and reliable software applications." box_src={I32} box_alt="Logo"/>

       <Service box_title=" Development Process" box_subtitle1="Open Communication:"
         box_para1="Throughout the development process, we maintain open communication and collaboration with your team, providing regular
         updates and seeking feedback.."
         box_subtitle2="Thorough Testing:" box_para2=" We conduct rigorous testing to ensure the
        quality and reliability of the software, addressing any issues or bugs that may arise." box_src={I33} box_alt="Logo" /> 

       <Service2 box_title="Deployment and Support" box_subtitle1="Smooth Deployment:"
        box_para1="Once the software is deployed, we provide ongoing support and maintenance to ensure its smooth operation and address any evolving needs or challenges."
        box_subtitle2="Continuous Support:" box_para2="Our team is available to provide ongoing support, including additional features, performance enhancements, or technical
         assistance as needed." box_src={I34} box_alt="Logo"/>    

        <Service box_title="Why Choose Custom Software?" box_subtitle1="Tailored Solutions:"
         box_para1=" Custom software solutions are tailored to your unique requirements, providing a perfect fit for your business needs."
         box_subtitle2="Scalability:" box_para2=" Custom software can scale with your business as it grows, adapting to new challenges and opportunities." 
         box_subtitle3="Competitive Advantage:" box_para3=" By investing in custom software, you gain a competitive edge by streamlining processes and optimizing efficiency.
         "box_src={I35} box_alt="Logo" />
        </>
    );
};

export default Custom;