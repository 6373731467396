import React from 'react';
import TitleContainer from '../../components/TitleContainer';
import I20 from '../../Resourses/Services/I20.jpg';
import I21 from '../../Resourses/Services/I21.jpg';
import I22 from '../../Resourses/Services/I22.jpg';
import I23 from '../../Resourses/Services/I23.jpg';
import I24 from '../../Resourses/Services/I24.jpg';
import I25 from '../../Resourses/Services/I25.jpg';
import { Service, Service2 } from '../../components/ServiceToolBox';

const MobileApp = () => {
    return (
        <>
        <TitleContainer attractive_banner = "From Concept To Completion" main_big_text = "Mobile App Development" small_description = "Innovative Solutions for the Mobile World" src_container1={I20} />
        <Service box_title="Platform Compatibility" 
       box_para1="Our mobile apps are developed to perform flawlessly across multiple platforms, including iOS and Android.
        Whether your target audience uses smartphones or tablets, we ensure that your app delivers a consistent and optimized experience on every device."
        box_src={I21} box_alt="Logo" /> 
     
     <Service2 box_title="User-Centric Design"
       box_para1="User experience is paramount in mobile app development. We design intuitive interfaces with user-friendly navigation and seamless interactions to create an engaging and enjoyable
        experience for your app users, encouraging longer sessions and higher retention rates."
      box_src={I22} box_alt="Logo"/>


      <Service box_title="Performance Optimization" 
      box_para1="Speed and performance are critical factors in mobile app success. Our development team employs best practices and optimization techniques to ensure that your app loads quickly,
       responds promptly to user actions, and delivers a smooth and responsive user experience even under heavy usage."
       box_src={I23} box_alt="Logo" /> 
    
    <Service2 box_title="Integration"
      box_para1="We understand the importance of seamless integration with third-party services and APIs to enhance the functionality of your app. Whether it's integrating with social media platforms, payment gateways,
       or location-based services, we ensure that your app works seamlessly with the tools and services your users rely on."
     box_src={I24} box_alt="Logo"/>

     <Service box_title="Security" 
      box_para1="Protecting user data and ensuring the security of your app is a top priority. We implement industry-leading security measures, including data encryption, secure authentication, and regular security audits,
       to safeguard user information and maintain the trust and confidence of your app users."
       box_src={I25} box_alt="Logo" />
        </>
    );
};

export default MobileApp;