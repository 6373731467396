import React from "react";
import './TitleContainer.css';

function TitleContainer(props){
  const attractive_banner = props.attractive_banner;
  const main_big_text = props.main_big_text;
  const small_description = props.small_description;
  const src_container1 = props.src_container1;
  const alt_container1 = props.alt_container1;
  return(
    <div className="mainpage-container1" >
        <div className="container1-column1">
            <h1 className="green-text">{attractive_banner}</h1>
            <p className="big-text">{main_big_text}</p>
            <p className="small-text">{small_description}</p>
        </div>
        <div className="container1-column2-image">
            {/* <img src = {image1} alt = "image1"/>  */}
            <img src={src_container1} alt={alt_container1} />
        </div>
    </div>
  )
}

export default TitleContainer