import React from "react";
import './ServiceToolBox.css';

export const Service=(props)=>{
    
    const box_title = props.box_title;
    const box_subtitle1 = props.box_subtitle1;
    const box_para1  = props.box_para1; 

    const box_subtitle2 = props.box_subtitle2;
    const box_para2  = props.box_para2; 

    const box_subtitle3 = props.box_subtitle3;
    const box_para3  = props.box_para3;

    const box_src = props.box_src;
    const box_alt = props.box_alt;
     return(
        <>
        <div className="service1-box">
        <div className="service1-text">
         <h1>{box_title}</h1>
         <p><b>{box_subtitle1}</b>{box_para1}</p>
         <p><b>{box_subtitle2}</b>{box_para2}</p>
         <p><b>{box_subtitle3}</b>{box_para3}</p>
        </div>
       <div className="service1-img">
        <img className="service1-logo"src={box_src} alt ={box_alt}/>
        </div>
    </div>
        </>
    )
}


export const Service2=(props)=>{
    const box_title = props.box_title;
    const box_subtitle1 = props.box_subtitle1;
    const box_para1  = props.box_para1; 

    const box_subtitle2 = props.box_subtitle2;
    const box_para2  = props.box_para2; 

    const box_src = props.box_src;
    const box_alt = props.box_alt;

    return(
    <>
    <div className="service2-box">
        <div className="service2-text">
         <h1>{box_title}</h1>
         <p><b>{box_subtitle1}</b>{box_para1}</p>
         <p><b>{box_subtitle2}</b>{box_para2}</p>
        </div>

        <div className="service2-img">
        <img className="service2-logo"src={box_src} alt ={box_alt}/>
        </div>
    </div>
    </>
    )
}
