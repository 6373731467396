import React from "react";
import './allourteam.css';

function Allteammates(props) {
    const person_img = props.person_img;
    const Person_name = props.Person_name;
    const Person_designation = props.Person_designation;
    const my_pic = props.my_pic;
    return(
        <div className="Teammates-persons">
            <div className="Teammates-image-div">
                <img className="im" src = {person_img} alt = {my_pic}/>
            </div>
            <div className="Person-text">
                <h2 className="Person-name">{Person_name}</h2>
                <h2 className="Person_designation">{Person_designation}</h2>
            </div>
        </div>
    )
}

export default Allteammates;