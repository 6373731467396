import React from "react";
import './footer.css';
import Logo from '../Resourses/Header&Footer/Logo.jpg'
export const Footer =()=>{
  return(
    <>
    {/* About us Blog OurTeam Projects logo */}
    <div className="footer">
      <div className="footer-box">
        <div className="footer-links">
          {/* links */}
          <div className="links">
          <div className="footer-first">
          <div>About us</div>
          <div>Our Team</div>
          </div>

          <div className="footer-second">
          <div>Blog</div>
          <div>Projects</div>
          </div>
          </div>


          <div className="footer-third">
          <img className="footer-logo"src={Logo} alt="logo"/>
          </div>
        </div>

    <hr className="footer-line"></hr>

    <div className="footer-text">
      <p>
          Based in Bangalore,<br></br>
           working world wide.
      </p>
     <a className="footer-link" href="#contact"><h1>Get In Touch</h1></a>
    </div>

    {/* green block */}
    <div className="footer-last">
      © 2023 Novaluna Technologies. All rights reserved. | Privacy Policy | Terms of Service    
      </div>
    
    
</div>
</div>
   </>
  );
  
}